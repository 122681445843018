import axios from 'axios';
import Vue from 'vue';
import type{ TranslateResult } from 'vue-i18n';
import { AuthenticationApiFactory } from '@/generated/api-client';
import { dmSignup } from '@/components';
import { AxiosErrorHandler, AxiosErrorHandlerBuilder } from '@/helpers';

declare const configuration: { platformURL:string };

const authneticationApi = AuthenticationApiFactory(undefined, '', axios);

interface State {
  logInError: TranslateResult;
  handleBackendError: AxiosErrorHandler;
}

export default Vue.extend({
  name: 'app',
  components: {
    dmSignup,
  },

  props: {
    showSignupOption: {
      type: Boolean,
      required: true,
    },
    navigateTo: {
      type: String,
      required: true,
    },
    authenticate: {
      type: Function,
      required: true,
    },
  },

  data(): State {
    return {
      logInError: '',
      handleBackendError: undefined as unknown as AxiosErrorHandler,
    };
  },

  mounted() {
    this.handleBackendError = new AxiosErrorHandlerBuilder()
      .when(400, { code: 33 }, () => {
        // account is blocked
        this.logInError = this.$t('account.blocked');
        this.$toast(this.$t('account.blocked'));
      })
      .when(400, { code: 48 }, () => {
        this.$toast(this.$t('dm.platform.communication.error'));
      })
      .when(400, { code: 55 }, () => {
        this.$toast(this.$t('request.body.invalid'));
      })
      .build();
  },

  computed: {
    pathToPlatform():string {
      return configuration.platformURL;
    },
  },

  methods: {
    async logInExternal({ token, state }: { token: string; state: string }) {
      try {
        await authneticationApi.logInExternal({ token, state });
        window.location.href = this.navigateTo;
      } catch (error) {
        const err = error as Error;
        console.error(`Error occurred during log in by means of DM Platform: ${err.message}`);
        this.handleBackendError(err);
      }
    },
  },
});
