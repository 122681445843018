import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import VueI18n from 'vue-i18n';
import Toast from 'vue-toastification';
import { layout, notFound, toastOptions } from '@/components';
import { createVueErrorHandler } from '@/helpers';
import { UnhandledRejectionToVue } from '@/plugins';
import logIn from './log-in.vue';
import messages from '../locale/en.json';
import header from './header.vue';
import applicationFooter from './footer.vue';
import 'vue-toastification/dist/index.css';
import oauthCallback from '../components/dm-authentication/oauth-callback.vue';

Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(VueI18n);
Vue.use(Toast, toastOptions);
Vue.use(UnhandledRejectionToVue);

const routes = [{
  path: '/',
  redirect: '/login',
}, {
  path: '/login',
  name: 'root',
  components: {
    header,
    content: logIn,
    footer: applicationFooter,
  },
  props: {
    content: {
      navigateTo: '/dashboard',
    },
  },
}, {
  path: '/login/oauthcallback',
  name: 'oauthcallback',
  components: {
    header,
    content: oauthCallback,
    footer: applicationFooter,
  },
}, {
  path: '/:pathMatch(.*)*',
  name: 'not-found',
  components: {
    header,
    content: notFound,
    footer: applicationFooter,
  },
  props: {
    content: {
      label: 'Go to Login',
      navigateTo: '/login',
    },
  },
}];

const router = new VueRouter({ routes, mode: 'history' });

router.afterEach(() => {
  // @ts-ignore
  router.app.$root.$toast.clear();
});

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: messages,
  },
});

const app = new Vue({
  i18n,
  router,
  render: (h) => h(layout),
}).$mount('#app');

Vue.config.errorHandler = createVueErrorHandler(app.$root.$toast);
