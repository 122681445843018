
import Vue from 'vue';
import qs from 'qs';

export default Vue.extend({
  async mounted(): Promise<void> {
    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const loginChannel = new BroadcastChannel('oauthLoginChannel');
    loginChannel.postMessage(queryParams.code); // send the code
    loginChannel.close();
    window.close();
  },
});
